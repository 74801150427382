/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .additional {
    &__subtitle {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &__text {
      word-break: break-all;
    }
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .additional {
    &__subtitle {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &__text {
      word-break: break-all;
    }
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .additional {
    &__subtitle {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &__text {
      word-break: break-all;
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (max-width: 480px) {
  .additional {
    &__text {
      word-break: break-all;
    }
    &__subtitle {
      margin-top: 10px;
      margin-bottom: 24px;
      text-align: center;
    }
  }
}
