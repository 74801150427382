.additional {
  &__subtitle {
    margin-top: 36px;
    margin-bottom: 36px;
    text-align: center;
  }
  &__text {
    text-align: justify;
    margin-bottom: 8px;
    line-height: 23px;
    align-self: flex-start;
  }
}
