.post {
  &__container {
    margin-bottom: 50px;
    display: flex;
    // align-items: center;
    // justify-content: center;
  }
  &__wrap {
    width: 540px;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    position: relative;
    @media screen and (max-width: 428px) {
      margin-top: 15px;
    }
  }
  &__wrapFullScreen {
    width: initial;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__block {
    width: 548px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 429px) {
      // width: 350px;
      margin-top: 15px;
      width: initial;
    }
    @media screen and (max-width: 415px) {
      // width: 300px;
      // width: 350px;
    }
    @media screen and (max-width: 395px) {
      // width: 300px;
      // width: 330px;
    }
    @media screen and (max-width: 390px) {
      // width: 300px;
      // width: 315px;
    }
    @media screen and (max-width: 376px) {
      // width: 300px;
      // width: 280px;
    }
    @media screen and (max-width: 360px) {
      // width: 300px;
      // width: 293px;
    }
    @media screen and (max-width: 320px) {
      // width: 270px;
      // width: 320px;
    }
  }
  &__user {
    display: flex;
    align-items: center;
    &-fullName {
      margin-left: 16px;
        @media screen and (max-width: 429px) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 90px;
    }
    }
    &__block {
      display: flex;
      align-items: center;
      width: 50%;
      @media screen and (max-width: 375px) {
        width: 56%;
      }
      @media screen and (max-width: 320px) {
        width: 50%;
      }
      button {
        margin-left: 12px;
        @media screen and (max-width: 768px) {
          margin-left: 15px;
        }
      }
    }
  }
  &__date {
    color: rgba(#050138, 0.6);
  }
  &__preview {
    width: 600px;
    height: 600px;
    margin-top: 20px;
    position: relative;
  }

  &__category {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: 15px;
    display: flex;
    &-name {
      display: flex;
      margin-top: 0;
    }

    &__title {
      word-break: break-word;
      color: #39363e;
      font-size: 23px;
      line-height: 34px;
      text-align: center;
      margin: 0;
    }

    &-link {
      padding: 5px 12px;
      // width: fit-content;
      // width: 100%;
      text-align: center;
      font-weight: 500;
      border-radius: 4px;
      word-break: break-word;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #ffffff;
      background: #da100b;
      display: flex;
      align-items: center;
      img {
        margin-left: 12px;
      }
    }
    &-admin {
      position: absolute;
      right: -65px;
      transform: rotate(90deg) translateY(-50%);
      top: 50%;
    }
  }
  &__buttons {
    z-index: 100;
    margin-bottom: 10px;
    position: relative;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 23px;
    &-count {
      display: none;
    }
    &-like {
      display: flex;
      align-items: center;
      margin-right: 15px;
      button {
        height: 24px;
        margin-right: 10px;
      }
      span {
        color: #96a0ab;
        font-size: 12px;
        line-height: 18px;
      }
    }
    &-share {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      &-text {
        margin-left: 5px;
        color: #96a0ab;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  &__editor {
    background: rgba(#2e8299, 0.2);
    border-radius: 8px;
  }
  &__form {
    margin-top: 8px;
    // height: 49px;
    width: 100%;
    position: relative;
    &-input {
      resize: none;
      padding: 14px 82px 14px 17px;
      // background: rgba(#2e8299, 0.2);
      background: #f1f1f1;
      overflow: hidden !important;
      border-radius: 8px;
      width: 100%;
      font-size: 14px;
      &--warning {
        color: tomato;
        font-size: 14px;
      }
    }
    &-button {
      position: absolute;
      // padding: 14px 14px;
      right: 0;
      top: 0;
      transition: opacity 0.1s ease-in;
      cursor: default;
      // background-color: #050138;
      color: #2e8299;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      opacity: 0.5;
      width: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 49px;

      &--active {
        cursor: pointer;
        opacity: 1;
      }
    }
  }

  &__button {
    background: #2e8299;
    border-radius: 6px;
    font-size: 12px;
    padding: 5px 12px;
    color: #ffffff;
    line-height: 14.06px;
    display: flex;
    align-items: center;
    &-active {
      color: #6c6c6c;
      background: #efefef;
    }
    img {
      margin-left: 10px;
    }
  }
}

.comments {
  &__publish {
    background-color: #2e8299 !important;
    color: #ffffff !important;
  }
  &__block {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-bottom: 10px;
    &__other-user {
      // padding-left: 10px;
      display: flex;
      flex-direction: row;

      &-wrap {
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  }
  &__pinIcon {
    width: 10px;
    height: 16px;
    position: absolute;
    left: -7px;
    transform: translate(-100%, 25%);
    top: 0;
  }
  &__pinButton {
    margin-right: 10px;
  }
  &__top {
    display: flex;
    align-items: baseline;
  }
  &__edit {
    margin-right: 10px;
    color: #2e8299;
    img {
      width: 15px;
      height: 15px;
    }
  }
  &__delete {
    img {
      width: 15px;
      height: 15px;
    }
  }
  &__block.pinned {
    img {
      display: block;
    }
  }
  &__fullName {
    margin-right: 8px;
    min-width: fit-content;
    margin-bottom: 2px;

    &-comment {
      margin-right: 8px;
      min-width: fit-content;
      margin-bottom: 2px;
      color: #a075c0;
    }
  }
  &__text {
    position: relative;
    width: 100%;
    word-break: break-word;
    color: #39363e;
    @media screen and (max-width: 428px) {
      ul {
        padding-left: 15px;
      }
    }
    &-button {
      margin-right: auto;
    }

    &-wrap {
      padding-left: 10px;
      border-left: 1px solid #2e8299;
    }
  }
  &__button {
    margin-top: 8px;
    margin-bottom: 8px;
    display: block;
  }
  &__likes {
    margin-top: 15px;
    margin-bottom: 7px;
    display: block;
    width: 100%;
  }
  .ReactModal__Overlay--after-open {
    position: relative !important;
  }
}

.previewStreams {
  width: 540px;
  height: 540px;
  position: relative;
  border-radius: 15px;
  background-color: rgba(#050138, 0.2);
  overflow: hidden;
  margin-top: 15px;
  @media (max-width: 481px) {
    width: initial;
    height: initial;
  }
  div {
    width: fit-content !important;
  }
  &--recommended {
    border: 2px solid #ee4c6e;
  }
  &__audio {
    img {
      width: 331px;
      height: 152px;
    }
  }
  &__video {
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 481px) {
      align-items: initial;
    }

    &-icon {
      position: absolute;
      width: 25px;
      height: 25px;
      bottom: 25px;
      right: 25px;
    }

    div {
      height: 540px !important;
      width: 540px !important;
      @media (max-width: 430px) {
        height: 230px !important;
        width: 368px !important;
      }
      @media (max-width: 415px) {
        height: 230px !important;
        width: 354px !important;
      }
      @media (max-width: 394px) {
        height: 230px !important;
        width: 330px !important;
      }
      @media (max-width: 376px) {
        height: 230px !important;
        width: 315px !important;
      }
      @media (max-width: 361px) {
        height: 230px !important;
        width: 300px !important;
      }
    }

    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transform: scale(-1, 1);
    }
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      object-fit: cover;
      width: 100%;
    }
    p {
      font-family: 'Kalam', cursive;
      font-style: normal;
      font-weight: 600;
      font-size: 23px;
      line-height: 34px;
      text-align: center;
      opacity: 0.7;
      color: #ffffff;
      @media screen and (min-width: 480px) {
        font-size: 50px;
        line-height: 50px;
      }
      @media screen and (min-width: 770px) {
        font-size: 60px;
        line-height: 60px;
      }
    }
    &--defaultImage {
      &::before {
        content: 'Read the text below';
        bottom: 26px;
        right: 29px;
        position: absolute;
        font-size: 16px;
        line-height: 19px;
      }
      align-items: center;
      display: flex;
      justify-content: center;
      img {
        width: 445px;
      }
    }
  }
}

.preview {
  width: 540px;
  height: 540px;
  position: relative;
  border-radius: 15px;
  background-color: rgba(#050138, 0.2);
  overflow: hidden;
  margin-top: 15px;
  div {
    width: fit-content !important;
  }
  &--recommended {
    border: 2px solid #ee4c6e;
  }
  &__audio {
    img {
      width: 331px;
      height: 152px;
    }
  }
  &__video {
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;

    &-icon {
      position: absolute;
      width: 25px;
      height: 25px;
      bottom: 25px;
      right: 25px;
    }

    div {
      height: inherit !important;
    }

    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      object-fit: cover;
      width: 100%;
    }
    p {
      font-family: 'Kalam', cursive;
      font-style: normal;
      font-weight: 600;
      font-size: 23px;
      line-height: 34px;
      text-align: center;
      opacity: 0.7;
      color: #ffffff;
      @media screen and (min-width: 480px) {
        font-size: 50px;
        line-height: 50px;
      }
      @media screen and (min-width: 770px) {
        font-size: 60px;
        line-height: 60px;
      }
    }
    &--defaultImage {
      &::before {
        content: 'Read the text below';
        bottom: 26px;
        right: 29px;
        position: absolute;
        font-size: 16px;
        line-height: 19px;
      }
      align-items: center;
      display: flex;
      justify-content: center;
      img {
        width: 445px;
      }
    }
  }
}
.finishWrap {
  width: 984px;
  height: 733px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 25%;
  @media (max-width: 1281px) {
    left: 12%;
  }
  @media (max-width: 835px) {
    left: 15%;
    width: 600px;
  }
  @media (max-width: 770px) {
    left: 12%;
  }
  @media (max-width: 430px) {
    left: 0%;
    height: 830px;
    width: 430px;
  }
  @media (max-width: 394px) {
    width: 390px;
  }
  @media (max-width: 391px) {
    left: 0%;
    height: 830px;
    width: 395px;
  }
  @media (max-width: 376px) {
    width: 375px;
  }
  .reactPlayer {
    transform: scale(-1, 1);
    margin-bottom: 28px;
    video::-webkit-media-controls-panel {
      transform: scale(-1, 1);
    }
    video::-webkit-media-controls-fullscreen-button {
      display: none;
    }
  }
}
.pinedPost {
  display: none;
}

.post__delimiter {
  border-bottom: 1px solid rgba(46, 130, 153, 0.5);
}
