// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');
* {
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
pre {
  margin: 0;
}

image {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: #2e8299;
}

body {
  background-color: #ffffff;
  font-size: 0;
}

.ReactModalPortal {
  font-size: 14px;
  overflow-x: hidden;
  overflow-y: auto;
}

body > *:not(#root):not(.ReactModalPortal) {
  display: none;
  font-size: 0;
}

#root {
  font-size: 14px;
}

input,
button,
textarea {
  outline: none;
  border: none;
  background: transparent;
}

input::-webkit-credentials-auto-fill-button {
  margin: 0;
  transform: scale(0) !important;
  width: 0;
  background-color: transparent;
  visibility: hidden !important;
  display: none;
}

input {
  -webkit-appearance: none;
}

button {
  cursor: pointer;
  padding: 0;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

body {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}

// li {
//   list-style: none;
// }

.link {
  color: #2e8299;
  cursor: pointer;
  &--underlined {
    text-decoration: underline;
  }
  &--normal {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: 0.15px;
  }
  &--small {
    font-size: 14px;
    line-height: 16px;
    opacity: 0.8;
  }
}

.header {
  font-weight: 500;
  opacity: 0.8;
  &--one {
    line-height: 56px;
    font-size: 48px;
  }
  &--two {
    font-size: 28px;
  }
  &--three {
    font-size: 24px;
    line-height: 28px;
  }
  &--four {
    font-size: 18px;
  }
}

.subtitle {
  opacity: 0.8;
  font-weight: 500;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  max-width: 669px;
  color: #111424;
}

.label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #111424;
  opacity: 0.8;
}

.text {
  opacity: 0.8;
  color: #39363e;
  &--normal {
    font-size: 16px;
    line-height: 19px;
  }
  &--bold {
    font-weight: 500;
  }
  &--small {
    font-size: 14px;
    line-height: 16px;
  }
  &--tiny {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
  &--center {
    text-align: center;
  }
}

.form {
  width: 340px;

  &__group {
    margin-top: 28px;
    position: relative;
  }
}

.formInput {
  width: 100%;
  border: 1px solid rgba(#050138, 0.4);
  border-radius: 4px;
  padding-left: 20px;
  letter-spacing: 0.15px;
  line-height: 24px;
  padding-right: 25px;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #111424;
  font-size: 16px;
  transition: border 0.2s ease-in-out;
  margin-bottom: 4px;
  transition: border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  &::placeholder {
    color: rgba(#050138, 0.6);
  }
  &:focus ~ .formNotification {
    opacity: 1;
  }
  &:focus {
    box-shadow: 0px 0px 0px 2px #050138;
    border: 1px solid transparent;
  }
  &:disabled {
    background: #dadada;
    border: none;
  }
}

.inputError ~ .formNotification {
  opacity: 1;
  color: #da100b;
}

.inputError {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #da100b;
  &:focus {
    box-shadow: 0px 0px 0px 2px #da100b;
    border: 1px solid transparent;
  }
}

.formLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.formNotification {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  opacity: 0.6;
  &--active {
    opacity: 1;
  }
  /* identical to box height, or 133% */
}

.wrap {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  padding: 60px 82px 75px 82px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 884px;
}
.wrapFullScreen {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  padding: 60px 82px 75px 82px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.button {
  cursor: pointer;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 4px;
  letter-spacing: 1.25px;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out;
  text-align: center;
  &--primary {
    background: #2e8299;
    &:hover,
    &:focus {
      background: #050138;
    }
  }
  &--outlined {
    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.12);
      background: rgba(98, 0, 238, 0.04);
      color: #6200ee;
    }
    &:focus {
      background: rgba(98, 0, 238, 0.12);
      border: 1px solid rgba(0, 0, 0, 0.12);
      color: #6200ee;
    }
    border: 1px solid #2e8299;
    background: transparent;
    color: #2e8299;
  }
}

.hint {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  z-index: 10000;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  width: 379px;
  left: 50px;
  top: 103%;
  &__header {
    margin-left: 20px;
    margin-bottom: 20px;
  }
  &__category {
    display: flex;
    width: 100%;
    padding-left: 11px;
    padding-top: 9px;
    padding-bottom: 13px;
    align-items: center;
    transition: 0.2s ease-in;
    border-bottom: 1px solid #e0e0e0;
    &:hover {
      background-color: rgba(#2e8299, 0.1);
    }
    &--focused {
      background-color: rgba(#2e8299, 0.1);
    }
    &-img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: #2e8299;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
    }
    &-info {
      display: flex;
      flex-direction: column;
    }
    &-name {
      margin-bottom: 8px;
      text-align: start;
    }
    &-posts {
      opacity: 0.6;
      text-align: start;
    }
  }
}

.ReactModal__Overlay {
  z-index: 1000 !important;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  z-index: 1000 !important;
  overflow-y: auto !important;
}

.show {
  opacity: 1 !important;
  pointer-events: auto !important;
}

.hide {
  opacity: 0;
  pointer-events: none;
  transform: scale(0) !important;
}

.home {
  &__container {
    display: flex;
    justify-content: center;
  }
  &__text {
    text-align: center;
    max-width: 600px;
    color: #000000;
    font-weight: 400;
  }
  &__wrap {
    position: relative;
    margin-left: 0;
    margin-right: 25px;
    min-height: 80vh;
    min-width: 885px;
  }
  &__wrapFullScreen {
    position: relative;
    margin-left: 0;
    margin-right: 25px;
    min-height: 80vh;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    width: 174px;
    .button {
      padding-left: 0;
      padding-right: 0;
      &--primary {
        color: #f5f4e8;
        margin-bottom: 16px;
      }
    }
  }
  &__button {
    position: fixed;
    bottom: 25px;
    left: 50%;
    margin-left: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
    button {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      background-color: #050138;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: transform 0.2s ease-in-out, opacity 0.2s ease-in;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
    img {
      transform: scale(0.7);
    }
    &-text {
      margin-top: 5px;
      color: #050138;
    }
  }
  &__mdButton {
    display: none;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 745px;
    height: 50px;
    background-color: rgba(#050138, 0.7);
    z-index: 100;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    &-text {
      color: #ffffff;
    }
  }
}

.editors-choice {
  &::after {
    content: "Advisor's choice";
    position: absolute;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #050138;
  }
  &--album {
    top: 8px;
    right: 8px;
  }
  &--post {
    top: 20px;
    right: 20px;
  }
}

.progress-bar {
  width: 100px;
  height: 100px;
}

.ql-editor {
  font-size: 16px;
  // min-height: 200px;
}

.ql-link {
  display: none !important;
}

.ql-toolbar.ql-snow .ql-picker-label svg polygon {
  stroke: #000000 !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-toolbar.ql-snow {
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.ql-container {
  border-radius: 12px;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #050138 !important;
}

.ql-toolbar.ql-snow {
  padding: 12px !important;
  border-bottom: 8px;
}

.ql-snow.ql-toolbar button .ql-snow.ql-toolbar button {
  &:hover ~ path {
    stroke: red !important;
  }
  // fill: red !important;
}

.feed {
  &__header {
    text-align-last: left;
    margin-right: auto;
  }
  &__category {
    margin-bottom: 32px;
    &:hover {
      background-color: transparent;
    }
    &-img {
      width: 77px;
      height: 77px;
      margin-right: 28px;
      img {
        width: 51px;
        height: 51px;
      }
    }
  }
}

.avatar {
  width: 42px !important;
  height: 42px !important ;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.stream {
  &__wrap {
    padding-top: 30px;
    background: #343434;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // max-width: none;
    // min-width: auto;
    @media (min-width: 768px) {
      padding-top: 30px;
      .post__wrap {
        margin-top: 0;
      }
    }
    @media (min-width: 992px) {
      padding-top: 30px;
    }
    @media (min-width: 1024px) {
      padding-top: 30px;
    }
    @media (min-width: 1200px) {
      padding-top: 30px;
    }
  }

  &__returnButton {
    color: #828282;
    display: flex;
    align-items: center;
    position: absolute;
    left: 32px;
    top: 38px;
    @media (max-width: 428px) {
      top: 10px;
      left: 13px;
    }
    img {
      height: 16px;
      margin-right: 12px;
    }
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }

  &__titleStream {
    display: flex;
    align-items: center;
    position: absolute;
    left: 96px;
    top: 28px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 34px;
    @media (max-width: 430px) {
      left: 80px;
    }
    @media (max-width: 415px) {
      left: 70px;
    }
    @media (max-width: 394px) {
      left: 55px;
    }
    @media (max-width: 376px) {
      left: 45px;
    }
    @media (max-width: 361px) {
      left: 36px;
    }
    @media (max-width: 321px) {
      left: 15px;
    }
  }
  &__returnButtonFullScreen {
    color: #828282;
    display: flex;
    align-items: center;
    position: absolute;
    left: 385px;
    top: 38px;
    img {
      height: 16px;
      margin-right: 12px;
    }
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }

  &__typography {
    color: #fff;
  }
  &__video {
    width: 540px;
    height: 606px;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
}
