/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .profile {
    &__wrap {
      width: 90%;
      margin: 0 auto;
      padding: 40px 25px 100px 25px;
    }
    &__container {
      display: flex;
      width: 100%;
      align-items: flex-start;
      padding: 0;
      margin-top: 30px;
      min-height: 70vh;
    }
    &__editButton {
      display: flex;
      justify-content: center;
      align-items: baseline;
    }
    &__user {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin-bottom: 43px;
      align-items: center;
      position: relative;
      &-status {
        // position: absolute;
        left: 15%;
        // right: 50%;
        transform: translate(0, 29%);
        // position: static;
      }
      &-name {
        display: flex;
        width: 70%;
        justify-content: space-around;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 20px;
      }
      &-info {
        width: 90%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
      }
      &-fullName {
        flex-basis: 100%;
        margin-bottom: 22px;
        margin-top: 27px;
      }
      &-bio {
        flex-basis: 100%;
        max-width: 100%;
        color: #111424;
        text-align: justify;
        word-wrap: break-word;
      }
    }
    &__posts {
      display: flex;
      width: 85%;
      margin-right: -20px;
      margin-top: 20px;
      flex-wrap: wrap;
      margin-left: 15px;
      justify-content: center;
    }
    &__defaultPost {
      width: 200px;
      height: 200px;
      margin-bottom: 20px;
    }
    &__button {
      margin-top: 20px;
      margin-bottom: 25px;
    }
    &__album {
      width: 200px;
      height: 200px;
      justify-content: center;
      align-items: center;
    }
  }

  .profileAvatar {
    width: 120px !important;
    height: 120px !important;
    margin-left: 50px;
  }

  .mobileDirectionColumn {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .profile {
    &__wrap {
      width: 90%;
      margin: 0 auto;
      padding: 40px 25px 100px 25px;
    }
    &__container {
      display: flex;
      width: 100%;
      align-items: flex-start;
      padding: 0;
      margin-top: 30px;
      min-height: 70vh;
    }
    &__editButton {
      display: flex;
      justify-content: center;
      align-items: baseline;
    }
    &__user {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin-bottom: 43px;
      align-items: center;
      position: relative;
      &-status {
        position: absolute;
        left: 8%;
        // right: 50%;
        transform: translate(0, 29%);
      }
      &-name {
        display: flex;
        width: 70%;
        justify-content: space-around;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 20px;
      }
      &-info {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
      }
      &-fullName {
        flex-basis: 100%;
        margin-bottom: 22px;
        margin-top: 27px;
      }
      &-bio {
        flex-basis: 100%;
        max-width: 100%;
        color: #111424;
        text-align: justify;
        word-wrap: break-word;
      }
    }
    &__posts {
      display: flex;
      width: 100%;
      // margin-right: -20px;
      margin-left: 0;
      margin-right: 0;
      margin-top: 20px;
      flex-wrap: wrap;
      // margin-left: 15px;
      justify-content: center;
    }
    &__defaultPost {
      width: 280px;
      height: 130px;
      margin-bottom: 20px;
    }
    &__button {
      margin-top: 20px;
      margin-bottom: 25px;
    }
    &__album {
      width: 130px;
      height: 130px;
      justify-content: center;
      align-items: center;
      &-delete {
        width: 35px;
        height: 35px;
      }
    }
  }

  .profileAvatar {
    width: 110px !important;
    height: 110px !important;
    margin-left: 50px;
  }

  .mobileDirectionColumn {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (max-width: 480px) {
  .profile {
    &__wrap {
      width: 100%;
      margin: 0 auto;
      padding: 40px 46px 40px 47px;
    }
    &__container {
      display: flex;
      width: 90%;
      align-items: flex-start;
      padding: 0;
      margin-top: 30px;
      min-height: 70vh;
    }
    &__editButton {
      display: flex;
      justify-content: center;
      align-items: baseline;
    }
    &__user {
      display: flex;
      flex-direction: column;
      margin-bottom: 43px;
      position: relative;
      &-status {
        left: 17%;
        transform: translate(0, -146%);
      }
      &-name {
        display: flex;
        width: 290px;
        justify-content: space-around;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 20px;
      }
      &-info {
        width: 310px;
        padding: 0 10px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
      }
      &-infoLabel {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 15px;
        span {
          margin-right: 3px;
        }
      }
      &-fullName {
        flex-basis: 100%;
        margin-bottom: -17px;
        margin-top: 27px;
        margin-left: 10px;
      }
      &-bio {
        flex-basis: 100%;
        max-width: 93%;
        margin-top: 25px;
        text-align: justify;
        color: #111424;
        word-wrap: break-word;
      }
    }
    &__posts {
      display: flex;
      width: 290px;
      margin-right: 0;
      margin-top: 20px;
      flex-wrap: wrap;
      margin-left: 15px;
    }
    &__defaultPost {
      width: 254px;
      height: 105px;
      margin-bottom: 20px;
    }
    &__button {
      margin-top: 20px;
      background: red;
    }
    &__album {
      width: 116px;
      height: 116px;
      justify-content: center;
      align-items: center;
      &-delete {
        width: 35px;
        height: 35px;
      }
    }
  }

  .profileAvatar {
    width: 100px !important;
    height: 100px !important;
    margin-left: 50px;
  }

  .mobileDirectionColumn {
    display: flex;
    flex-direction: column;
  }
}
