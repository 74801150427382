/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .selectFileWidth {
    width: 760px;
    height: 750px;
  }

  .selectFile {
    &__button {
      display: block;
      color: #f5f4e8;
      margin-bottom: 24px;
    }
    &__header {
      margin-bottom: 5px;
    }
    &__text {
      margin-top: 15px;
      text-align: center;
      max-width: 80%;
      // margin: 0 10px 30px 10px;
    }
  }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .selectFileWidth {
    width: 760px;
    height: 750px;
  }

  .selectFile {
    &__button {
      display: block;
      color: #f5f4e8;
      margin-bottom: 24px;
    }
    &__header {
      margin-bottom: 5px;
    }
    &__text {
      margin-top: 15px;
      text-align: center;
      max-width: 80%;
    }
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .selectFileWidth {
    width: 760px;
    height: 700px;
  }

  .selectFile {
    &__button {
      display: block;
      color: #f5f4e8;
      margin-bottom: 24px;
    }
    &__header {
      margin-bottom: 5px;
      text-align: center;
    }
    &__text {
      margin-top: 10px;
      text-align: center;
      max-width: 80%;
    }
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .selectFileWidth {
    width: 470px;
    max-height: 100vh;
    overflow-y: auto;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .selectFileWidth {
    width: 470px;
  }

  .selectFile {
    &__button {
      display: block;
      color: #f5f4e8;
      margin-bottom: 24px;
    }
    &__header {
      margin-bottom: 5px;
    }
    &__text {
      margin-top: 10px;
      text-align: center;
      max-width: 80%;
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (max-width: 480px) {
  .selectFileWidth {
    width: 90%;
    padding-top: 40px;
  }

  .selectFile {
    &__button {
      display: block;
      color: #f5f4e8;
      margin-bottom: 24px;
    }
    &__header {
      margin-bottom: 5px;
      text-align: center;
    }
    &__text {
      margin-top: 0px;
      text-align: center;
      max-width: 80%;
    }
  }
}
