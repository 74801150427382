.StreamsSliders {
  margin: 0 auto;
  width: 100%;
  max-width: 884px;
  padding-right: 27px;
  box-sizing: border-box;

  @media screen and (max-width: 1280px) {
    max-width: 735px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 745px;
  }
  @media screen and (max-width: 767px) {
    max-width: 700px;
  }

  &-wrap {
    @media screen and (min-width: 1280px) {
      margin-left: -27px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 30px;
    }
    @media screen and (max-width: 480px) {
      padding: 0 15px;
    }
    margin-bottom: 30px;
  }
}

.card-Slider {
  cursor: pointer;
  width: 83px;

  &-image {
    &-avatar {
      width: 69px !important;
      height: 69px !important;
    }
    padding: 4px;
    border: 3px solid #ff6262;
    border-radius: 50%;
    width: max-content;
  }

  &-name {
    color: #39363e;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
    text-align: center;
    margin: 7px 0 0;
  }

  &-status {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    &-live {
      color: #ff6262;
    }
  }

  &-activeStreamCard {
    opacity: 0.5;
    .card-Slider-name {
      color: #ffffff;
    }
  }
}

.StreamModal {
  max-width: 984px;
  width: 100%;
  // height: 484px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  padding: 37px;
  margin: 0 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  max-height: 80%;
  @media (max-width: 835px) {
    width: 91%;
    margin: 0;
  }
  @media (max-width: 480px) {
    left: 44%;
    width: 100%;
    margin: 0 25px;
  }
  @media (max-width: 370px) {
    left: 43%;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-content {
      display: flex;
      align-items: center;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-button {
    display: flex;
    align-items: center;
    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #828282;
      margin-left: 10px;
    }
  }
  &-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 34px;
    color: #39363e;
    margin-left: 18px;
  }

  &-content {
    width: 360px;
    margin: 0 auto;

    @media (max-width: 480px) {
      width: auto;
    }

    &-label {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #39363e;
      margin-bottom: 10px;
    }

    &-textField {
      background: #f1f1f1;
      resize: none;
      border-radius: 8px;
      min-height: 200px;
      font-size: 16px !important;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #111424;
    }

    &-textFieldFinish {
      background: #f1f1f1;
      resize: none;
      border-radius: 8px;
      max-height: 100%;
      max-width: 361px;
      font-size: 16px !important;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #111424;
      .ql-editor {
        max-height: 80px;
      }
    }

    &-description {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: normal;
      font-size: 8px;
      line-height: 12px;
      text-align: center;
      margin-top: 40px;

      span {
        color: #d43053;
      }
    }

    &-button {
      width: 118px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #828282;
      box-sizing: border-box;
      border-radius: 8px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #828282;
      margin: 16px auto 0;
    }

    &-span {
      margin-top: 6px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: normal;
      font-size: 8px;
      line-height: 12px;
      color: #828282;
    }
  }
}
.commentsTextStream {
  color: #fff;
  hr {
    background: #fff;
  }
}
.streamHeaderLeft {
  display: flex;
  justify-content: space-between;
  width: 38%;
  .headerTitle {
    color: #fff;
    font-size: 12px;
    align-items: center;
    display: flex;
    cursor: pointer;
    span {
      margin-left: 5px;
    }
  }
  p {
    color: #fff;
    font-size: 12px;
    align-items: center;
    display: flex;
    .cirlceLife {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      margin-right: 5px;
      background: #ff6262;
    }
    &:last-child {
      color: #ff6262;
    }
    @media (max-width: 790px) {
      width: 140px;
    }
  }
  @media (max-width: 790px) {
    flex-direction: column;
    // width: 37%;
  }
  // @media screen and (max-width: 394px) {
  //   width: 40%;
  // }
  // @media screen and (max-width: 390px) {
  //   width: 42%;
  // }
  // @media screen and (max-width: 360px) {
  //   width: 45%;
  // }
}
// .preview {
//   width: 541px !important;
//   height: 606px !important;
// }
.streamContent {
  @media (max-width: 790px) {
    padding: 0;
  }
}
.streamInputWrap {
  min-width: 540px;
  .titleInputPubl,
  .descriptionPubl {
  }

  @media (max-width: 790px) {
    min-width: 100%;
  }
}
.postStream {
  color: #fff;
  hr {
    background: #fff;
  }
  .streamComment {
    .post__form-input {
      background: #646464;
    }
    .comments__publish {
      background-color: #3a3a3a !important;
    }
  }
}
.contentMediaStream {
  position: relative;

  .bntMedia {
    position: absolute;
    z-index: 9;
    left: 50%;
    margin-right: -50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    width: 315px;
    background: #00000080;
    border-radius: 10px;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 790px) {
      width: 100%;
    }
  }
  .wrapBtnMedia {
    width: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      cursor: pointer;
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
    }
    .itemMedia {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .fullScreenBtn {
    position: absolute;
    right: 0;
    top: 80px;
    cursor: pointer;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
  }
  .lifeTimeContent {
    position: absolute;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -50%;
    top: 24px;
    transform: translate(-50%, -50%);
    span {
      color: #ff6262;
    }
    img {
      margin-right: 10px;
    }
  }
}
.streamTitleText {
  color: #fff;
  font-size: 23px;
  .streamTitleBlock {
    width: 350px;
    @media (max-width: 790px) {
      width: auto;
    }
  }
  .liveText {
    color: #ff6262;
  }
}
.streamNameSlider {
  color: #fff;
}
.publishBtnWrap {
  max-width: 540px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  .deleteStreamBtn {
    width: 45%;
    border: 1px solid #ff6262;
    height: 38px;
    color: #ff6262;
    font-size: 12px;
    border-radius: 8px;
    @media (max-width: 790px) {
      width: 100%;
    }
    @media (max-width: 376px) {
      width: 75%;
    }
    @media (max-width: 321px) {
      width: 50%;
    }
  }
  .publishStreamBtn {
    width: 45%;
    border: 1px solid #828282;
    height: 38px;
    font-size: 12px;
    color: #828282;
    border-radius: 8px;
    @media (max-width: 790px) {
      width: 100%;
    }
    @media (max-width: 376px) {
      width: 75%;
    }
    @media (max-width: 321px) {
      width: 50%;
    }
  }
  @media (max-width: 790px) {
    flex-direction: column;
  }
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #828282;
}

.ql-editor {
  max-height: 200px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.videoControlsTopWrap {
  display: flex;
  align-items: center;
  justify-content: center;

  .videoControlsTop {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 29px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #39363e;
    img {
      margin-right: 11px;
    }
  }
}

.videoControlsBottom {
  width: 315px;
  height: 74px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 100px;
  border-radius: 10px;
  @media (max-width: 428px) {
    left: 25px;
  }
  @media (max-width: 414px) {
    left: 20px;
  }
  @media (max-width: 400px) {
    left: 10px;
  }
  @media (max-width: 394px) {
    left: 10px;
  }
  @media (max-width: 391px) {
    left: 7px;
  }
  @media (max-width: 376px) {
    left: 12px;
    width: 290px;
  }
  @media (max-width: 360px) {
    left: 12px;
    width: 275px;
  }
  @media (max-width: 322px) {
    left: 10px;
    width: 240px;
  }
  img {
    margin: 20px;
  }
}

.dropDownWrapper {
  width: 576px;
  height: 376px;
  background: #444444;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 0px;
  position: absolute;
  z-index: 9999;
  right: 155px;
  margin-top: 35px;
  @media (max-width: 1280px) {
    right: 78px;
  }
  @media (max-width: 428px) {
    right: 8px;
    width: 383px;
  }
  @media (max-width: 415px) {
    right: 0px;
  }
  @media (max-width: 394px) {
    right: 0px;
    width: 360px;
  }
  @media (max-width: 376px) {
    right: 0px;
    width: 345px;
  }
  @media (max-width: 360px) {
    right: 0px;
    width: 330px;
  }
  .crossDropDown {
    position: absolute;
    right: 0;
    padding-right: 16px;
    padding-top: 21px;
    cursor: pointer;
  }
  .titleDropDown {
    padding: 15px;
    color: #ffffff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    border-bottom: 0.5px solid #c2c2c2;
  }
  .viewersInfo {
    padding: 15px;
    border-bottom: 0.5px solid #c2c2c2;
    display: flex;
    align-items: center;
    .viewersCred {
      margin-left: 15px;
      .usernameViewers {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
      }
      .fullNameViewers {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }
}

.videoMaster {
  width: 541px;
  height: 607px;
  border-radius: 15px;
  object-fit: fill;
  margin-top: 11px;
  transform: scale(-1, 1);

  @media (max-width: 428px) {
    width: 365px;
    height: 323px;
  }
  @media (max-width: 415px) {
    width: 355px;
  }
  @media (max-width: 400px) {
    width: 335px;
  }
  @media (max-width: 393px) {
    width: 335px;
  }
  @media (max-width: 390px) {
    width: 330px;
  }
  @media (max-width: 376px) {
    width: 315px;
  }
  @media (max-width: 360px) {
    width: 300px;
  }
  @media (max-width: 320px) {
    width: 262px;
  }
}
