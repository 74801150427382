.editProfile {
  &__group {
    margin-bottom: 16px;
  }
  &__wrap {
    padding-right: 0;
    padding-left: 100px;
    // display: flex;
  }
  &__form {
    width: fit-content;
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 108px;
    &-group {
      display: flex;
      flex-direction: row;
      position: relative;
    }
  }
  &__formInput {
    width: 449px;
    padding-left: 14px;
    &:focus + .editProfile__text {
      color: #050138;
    }
  }
  &__textarea {
    max-width: 449px;
    min-width: 449px;
    height: 134px;
    max-height: 300px;
    min-height: 134px;
  }
  &__label {
    // margin-right: 40px;
    width: fit-content;
    top: 15px;
    right: 110%;
    position: absolute;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #111424;
    span {
      font-weight: 400;
    }
  }
  &__text {
    max-width: 449px;
    color: rgba(0, 0, 0, 0.6);
    transition: color 0.2s ease-in-out;
  }

  &__user {
    display: flex;
    align-self: flex-start;
    margin-left: 18px;
  }
  &__info {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__userName {
    margin: 0;
  }
  &__header {
    margin-bottom: 36px;
  }
  &__link {
    margin-left: auto;
    display: block;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    .react-ripples {
      width: fit-content;
    }
  }
  &__save {
    margin-left: 20px;
  }
}

.group-error {
  .editProfile__label {
    color: #da100b;
  }
  // .editProfile__formInput:focus + .editProfile__text {
  //     opacity: 1;
  //     color: #da100b !important ;
  // }
  .editProfile__formInput + .editProfile__text {
    color: #da100b;
  }
}

.changeAvatar {
  display: flex;
  align-items: center;
  flex-direction: column;
  &__wrap {
    padding: 0 45px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__text {
    margin-bottom: 18px;
    max-width: 338px;
    margin-left: auto;
    margin-right: auto;
  }
  svg {
    display: none !important;
  }
  hr {
    width: 100%;
  }
  &__buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    bottom: 31px;
  }
  &__cancel {
    margin-right: 20px;
  }
  &__head {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    color: #111424;
  }
  &__close {
    position: absolute;
    right: 13px;
  }
  label {
    transition: background 0.2s ease-in-out, border 0.2s ease-in-out,
      color 0.1s ease-out !important;
    &:hover,
    &:focus {
      background: #050138 !important;
    }
  }
}

.editProfileAvatar {
  width: 150px !important;
  height: 150px !important;
}

.user {
  &__avatar {
    margin-right: 57px;
    font-weight: 500;
    font-size: 48px;
    line-height: 94px;
  }
}
