.profile {
  &__wrap {
    width: 955px;
    min-height: 50vh;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 32px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    padding: 40px 46px 100px 47px;
  }
  &__container {
    display: flex;
    width: fit-content;
    align-items: flex-start;
    padding: 0 30px;
    margin: 0 auto;
    margin-top: 47px;
    padding-left: -140px;
    min-height: 70vh;
  }
  &__editButton {
    display: flex;
    justify-content: center;
    align-items: baseline;
    color: #828282;
    span {
      margin-left: 11px;
    }
  }
  &__user {
    display: flex;
    flex-direction: column;
    margin-bottom: 43px;
    position: relative;
    &-status {
      position: absolute;
      left: -5px;
      top: 1px;
      transform: translate(-100%, 0);
    }
    &-name {
      display: flex;
      width: 525px;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    &-info {
      width: 438px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &-button {
        color: #000000;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &-fullName {
      flex-basis: 100%;
      margin-bottom: 22px;
      margin-top: 27px;
    }
    &-bio {
      margin-top: 27px;
      flex-basis: 100%;
      max-width: 428px;
      color: #39363e;
      word-wrap: break-word;
    }
  }
  &__posts {
    display: flex;
    width: 822px;
    margin-right: -20px;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  &__defaultPost {
    width: 254px;
    height: 254px;
    margin-right: 20px;
    background: rgba(#2e8299, 0.2);
    border-radius: 16px;
    border: 2px dashed #2e8299;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2e8299;
  }
  &__button {
    margin-top: 20px;
    margin-bottom: 25px;
  }
  &__albumStreams {
    width: 254px;
    height: 254px;
    cursor: pointer;
    margin-right: 15px;
    // margin-right: 20px;
    background-color: rgba(#050138, 0.2);
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-family: 'Kalam', cursive;
      position: absolute;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 14px;
      text-align: center;
      opacity: 0.8;
      color: #ffffff;
      word-break: break-word;
      @media screen and (min-width: 770px) {
        font-size: 30px;
        line-height: 27px;
      }
    }

    &--image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &--audio {
      margin-top: 20px;
      width: 140px;
    }
    &--text {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &--video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(-1, 1);
    }
    &-play {
      opacity: 0.6;
      width: 75px;
      height: 75px;
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-40%, -50%);
    }

    &-empty {
      opacity: 0;
      width: 254px;
      height: 254px;
    }
    &-delete {
      position: absolute;
      bottom: 0;
      z-index: 1000;
      right: 0;
      transition: transform 0.2s ease-in;
      width: 50px;
      height: 50px;
      &:hover {
        transform: scale(110%);
        z-index: 100;
      }
    }
  }
  &__album {
    width: 254px;
    height: 254px;
    cursor: pointer;
    margin-right: 15px;
    // margin-right: 20px;
    background-color: rgba(#050138, 0.2);
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-family: 'Kalam', cursive;
      position: absolute;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 14px;
      text-align: center;
      opacity: 0.8;
      color: #ffffff;
      word-break: break-word;
      @media screen and (min-width: 770px) {
        font-size: 30px;
        line-height: 27px;
      }
    }

    &--image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &--audio {
      margin-top: 20px;
      width: 140px;
    }
    &--text {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &--video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-play {
      opacity: 0.6;
      width: 75px;
      height: 75px;
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-40%, -50%);
    }

    &-empty {
      opacity: 0;
      width: 254px;
      height: 254px;
    }
    &-delete {
      position: absolute;
      bottom: 0;
      z-index: 1000;
      right: 0;
      transition: transform 0.2s ease-in;
      width: 50px;
      height: 50px;
      &:hover {
        transform: scale(110%);
        z-index: 100;
      }
    }
  }
  &__buttonBlock {
    width: 100%;
    display: flex;
    align-items: center;
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      padding: 18px 0;
      font-size: 12px;
      color: #c2c2c2;
      border-bottom: 0.5px solid #c2c2c2;
      cursor: pointer;

      img {
        margin-right: 8px;
      }

      &-active {
        color: #39363e;
        border-bottom: 0.5px solid #39363e;
      }
    }
  }
}

.mobileDirectionColumn {
  display: flex;
  flex-direction: row;
}

.profileAvatar {
  width: 150px !important;
  height: 150px !important;
}
