/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .signUpModal {
    padding: 40px;
    &__input {
      padding-right: 20px;
    }
    &__button {
      position: static;
      margin-top: 25px;
    }
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
  .changePasswordModalWidth {
    overflow-y: auto;
    max-height: 100vh;
    width: 400px;
  }

  .followersModalWidth {
    overflow-y: auto;
    max-height: 100vh;
    width: 50%;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .shareModal {
    width: 200px;
    height: auto;
    &__text {
      font-size: 10px;
    }
    &__header {
      margin-bottom: 5px;
    }
    &__close {
      top: 15px;
      right: 10px;
      img {
        transform: scale(0.7);
      }
    }
    &__social {
      display: flex;
      margin-bottom: 5px;
      justify-content: space-between;
    }
    &__buffer {
      margin-top: 0px;
      padding: 10px;
      font-size: 8px;
      &-icon {
        position: absolute;
        right: 5px;
        top: 50%;
      }
    }
    &__copyImage {
      width: 15px;
      height: 15px;
    }
  }

  .shareModalIcon {
    width: 30px;
    height: 30px;
  }

  .changePasswordModalWidth {
    width: 400px;
  }

  .signUpModal {
    &__input {
      padding-right: 20px;
    }
    &__button {
      position: static;
      margin-top: 25px;
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (max-width: 480px) {
  .modal {
    &__paragraph {
      width: 150px;
    }
  }

  .shareModal {
    width: 170px;
    height: auto;
    left: 30px;
    bottom: 43px;
    padding: 15px 15px;
    &__text {
      font-size: 10px;
    }
    &__header {
      margin-bottom: 5px;
    }
    &__close {
      top: 10px;
      right: 10px;
      transform: scale(0.8);
    }
    &__social {
      display: flex;
      margin-top: 5px;
      margin-bottom: 5px;
      justify-content: space-between;
      padding: 0 10px;
    }
    &__buffer {
      margin-top: 0px;
      padding: 5px;
      font-size: 7px;
      &-icon {
        position: absolute;
        right: 5px;
        top: 50%;
      }
    }
    &__copyImage {
      width: 10px;
      height: 10px;
    }
  }

  .shareModalIcon {
    width: 25px;
    height: 25px;
  }

  .followersModalWidth {
    width: 90%;
  }

  .followersModal {
    padding-bottom: 5px;
    margin: 0 auto;
    &__list {
      max-height: 300px;

      &-item {
        align-items: flex-start;
        display: flex;
        align-items: center;
      }
    }
    &__button {
      margin-left: 15px;
    }
  }

  .follower {
    display: flex;
    align-items: center;
    margin-right: auto;
    justify-content: space-between;
    flex-direction: row;
    &__info {
      margin-left: 8px;
      &-fullName {
        color: rgba(#111424, 0.6);
      }
      &-username {
        margin-bottom: 8px;
      }
    }
  }

  .modalWithButtonWidth {
    width: 90%;
    margin: 0 auto;
  }
  .changePasswordModalWidth {
    width: 90%;
  }

  .changePassword {
    &__top {
      padding-top: 30px;
      padding-bottom: 23px;
      margin-bottom: 40px;
    }
    &__form {
      width: 100%;
      padding: 0;

      &-group {
        width: 270px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 14px;
      }
      .modal__bottom {
        display: flex;
        justify-content: center;
      }
    }
    &__formInput {
      padding: 8px 15px;
      &::placeholder {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .signUpModal {
    padding: 30px;
    &__input {
      padding-right: 20px;
    }
    &__button {
      position: static;
      margin-top: 25px;
    }
  }

  #share-form {
    width: 100%;
  }
}
