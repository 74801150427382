.navbar {
  margin-bottom: 15px;
  padding: 9px 0;
  &__container {
    z-index: 1000;
    display: flex;
    justify-content: space-between;
  }
  &__buttons {
    &-register {
      margin-right: 20px;
    }
  }
  &--dark {
    background: #050138;
  }
  &__container {
    display: flex;
    align-items: center;
  }
  &__avatar {
    margin-right: 23px;
  }
  &__settings {
    display: flex;
    align-items: center;
    margin-left: auto;
    position: relative;
  }
}

.searchbar {
  margin-left: 67px;
  width: 576px;
  position: relative;

  &__input {
    width: 100%;
    background-color: #fff;
    padding-left: 56px;
    margin-bottom: 0;
    &::placeholder {
      color: #828282;
    }
  }
  &__button {
    position: absolute;
    left: 19px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__hint {
    width: 500px;
  }
  &__tabs {
    display: flex;
    justify-content: space-between;
  }
  &__tab {
    flex-basis: 50%;
    text-align: center;
    padding: 20px 0;
    transition: background-color 0.2s ease-in;
    &--active {
      background-color: rgba(#2e8299, 0.1);
    }
  }
  &__item {
    transition: background-color 0.2s ease-in;
    &:hover {
      background-color: rgba(#2e8299, 0.1);
      cursor: pointer;
    }
  }
  .likeBlock{
    color: #96A0AB;
    display: flex;
    align-items: center;
    img{
      margin-right: 8px;
    }
  }
}

@media (max-width: 1200px) {
  .searchbar {
    width: 100%;
    margin-left: 0px;
    margin-right: 50px;
    &__hint {
      left: 0;
    }
  }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .searchbar {
    margin-left: 50px;
    &__hint {
      width: 380px;
      left: -50px;
    }
  }
}

@media (max-width: 576px) {
  .navbar {
    &__logo {
      width: 150px;
      img {
        max-width: 100%;
      }
    }
    &__buttons {
      text-align: center;
      &-register {
        margin-left: 20px;
        margin-bottom: 10px;
      }
    }
    &__container {
      flex-wrap: wrap;
      padding-left: 25px;
      padding-right: 25px;
    }
    &__settings {
      margin-left: 0;
    }
    &__avatar {
      margin-right: 10px;
    }
    padding-bottom: 15px;
  }

  .searchbar {
    margin-left: 0;
    margin-right: 0;

    margin-top: 15px;
    order: 2;
    &__hint {
      width: 100%;
      left: 0;
    }
    &__input {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.scrollWrap{
 overflow: hidden;
  .sb-avatar{
    overflow: hidden;
  }
  div:first-child{
    overflow-x: hidden !important;
    &::-webkit-scrollbar {
      width: 6px;
      background-color: #e5e5e5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #2e8299;
    }
    a{
      overflow: hidden !important;
    }
  }
}