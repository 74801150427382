/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .editProfile {
    &__group {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__wrap {
      padding-right: 0;
      padding-left: 0;
    }
    &__form {
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 20px 0 0 0;
      &-group {
        flex-direction: column;
      }
    }
    &__formInput {
      width: 449px;
      padding-left: 14px;
    }
    &__textarea {
      max-width: 449px;
      min-width: 449px;
    }
    &__label {
      // margin-right: 40px;
      position: static;
      padding-bottom: 5px;
    }
    &__text {
      max-width: 449px;
    }

    &__user {
      display: flex;
      align-self: center;
      margin-left: 18px;
    }
    &__info {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
    }
    &__userName {
      margin: 0;
    }
    &__header {
      margin-bottom: 36px;
    }
    &__link {
      margin-left: auto;
      display: block;
    }
    &__buttons {
      justify-content: center;
    }
  }

  .editProfileAvatar {
    width: 80px !important;
    height: 80px !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (max-width: 480px) {
  .editProfile {
    &__group {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__wrap {
      padding-right: 0;
      padding-left: 0;
    }
    &__form {
      width: fit-content;
      margin: 50px auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      &-group {
        display: flex;
        flex-direction: column;
        position: relative;
      }
    }
    &__formInput {
      width: 90%;
      padding-left: 14px;
    }
    &__textarea {
      max-width: 90%;
      min-width: 90%;
    }
    &__label {
      // margin-right: 40px;
      position: static;
      padding-left: 20px;
      padding-bottom: 5px;
    }
    &__text {
      text-align: justify;
      max-width: 320px;
      padding: 0 20px 8px 20px;
    }

    &__info {
      justify-content: space-around;
    }
    &__buttons {
      justify-content: center;
    }
    &__link {
      margin-left: auto;
      display: block;
      margin-right: 20px;
    }
  }

  .editProfileAvatar {
    width: 80px !important;
    height: 80px !important;
  }
}
