/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .post {
    &__wrap {
      width: 400px;
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      position: relative;
      .ql-toolbar.ql-snow {
        padding: 12px 40px 12px 12px !important;
      }
    }
    &__preview {
      width: 400px;
      height: 400px;
      margin-top: 20px;
      position: relative;
    }
    &__category {
      position: static;
      transform: none;
      margin-top: 10px;
      justify-content: space-between;
      &-name {
        top: auto;
        text-align: right;
      }
    }
    &__title {
      flex-basis: 75%;
      text-align: start;
    }
    &__buttons {
      margin-left: 0;
      order: 1;
      margin-top: 15px;
      &-count {
        display: block;
        margin-right: 10px;
      }
      &-like,
      &-share {
        transform: scale(0.9);
      }
      &-share {
        margin-left: 5px;
      }
      &-like {
        margin-right: 5px;
      }
    }
  }
  .preview {
    width: 400px;
    height: 400px;
    margin-top: 15px;
  }

  .comments {
    &__likes {
      width: fit-content;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 5px;
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (max-width: 350px) {
  .post {
    &__category {
      &-link {
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 480px) {
  .pinedPost {
    display: block;
    background: none;
  }
  .pinedPostTable {
    display: none;
  }
  .post {
    &__container {
      margin-bottom: 10px;
      width: 100%;
    }
    &__wrap {
      width: 100%;
    }
    &__form {
      &-input {
        font-size: 13px;
        // height: 42px !important;
        padding: 15px 82px 14px 17px;
      }
      &-button {
        height: 49px;
        background-color: transparent;
        color: #050138;
        // top: 6px;
      }
    }
    &__preview {
      width: 295px;
      height: 295px;
    }
    &__category {
      position: static;
      transform: none;
      flex-wrap: nowrap;
      margin-top: 10px;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      &-admin {
        font-size: 12px;
        right: -50px;
      }
      &-name {
        position: static;
        max-width: none;
        text-align: center;
        button {
          margin-top: 20px;
        }
      }
    }
    &__title {
      max-width: 100%;
    }
    &__buttons {
      margin-top: 10px;
      margin-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      &-count {
        display: block;
        margin-right: 10px;
      }
      &-like,
      &-share {
        transform: scale(0.8);
      }
      &-like {
        margin-right: 5px;
      }
    }
  }
  .mobilePin {
    background: none;
  }
  .post__button {
    span {
      display: none;
    }
    .pinedPost {
      width: 15px;
    }
    img {
      margin-left: 0;
    }
  }
  .preview {
    height: 230px;
    width: 100%;
    margin-top: 15px;
    &__image {
      display: flex;
      img {
        object-fit: cover;
        width: 100%;
      }
    }
  }

  .comments {
    &__likes {
      width: fit-content;
      margin-top: 0;
      margin-bottom: 0;
    }
    &__text {
      &-button {
        position: static;
        margin-top: 12px;
      }
    }
  }
}
