/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .home {
    &__wrap {
      min-width: 735px;
      margin: 0 auto;
    }
    &__button {
      left: 47%;
    }
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .header {
    &--one {
      font-size: 36px;
    }
    &--two {
      font-size: 24px;
    }
    &--three {
      font-size: 20px;
    }
    &--four {
      font-size: 18px;
    }
  }
  .subtitle {
    font-size: 20px;
  }

  .wrap {
    padding: 20px 40px 20px 40px;
    max-width: 735px;
  }

  .home {
    &__wrap {
      min-width: 735px;
      margin: 0 auto;
    }
    &__button {
      margin-left: 305px;
    }
    &__mdButton {
      width: 735px;
      display: block;
    }
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .header {
    font-weight: 500;
    &--one {
      line-height: 56px;
      font-size: 36px;
    }
    &--two {
      font-size: 24px;
    }
    &--three {
      font-size: 20px;
      line-height: 28px;
    }
    &--four {
      font-size: 18px;
    }
  }
  .subtitle {
    font-size: 20px;
    max-width: 767px;
  }

  .text {
    color: #050138;
    &--normal {
      font-size: 16px;
      line-height: 19px;
    }
    &--bold {
      font-weight: 500;
    }
    &--small {
      font-size: 14px;
      line-height: 16px;
    }
    &--tiny {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
    &--center {
      text-align: center;
    }
  }

  .form {
    width: 340px;
  }

  .wrap {
    padding: 60px 20px 75px 20px;
    max-width: 745px;
  }

  .home {
    &__wrap {
      min-width: 745px;
      margin: 0 auto;
    }

    &__mdButton {
      display: block;
      width: 745px;
    }
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .header {
    font-weight: 500;
    &--one {
      line-height: 56px;
      font-size: 32px;
    }
    &--two {
      font-size: 24px;
    }
    &--three {
      font-size: 18px;
      line-height: 28px;
    }
    &--four {
      font-size: 14px;
    }
  }
  .subtitle {
    font-size: 18px;
    max-width: 481px;
  }

  .text {
    color: #050138;
    &--normal {
      font-size: 16px;
      line-height: 19px;
    }
    &--bold {
      font-weight: 500;
    }
    &--small {
      font-size: 14px;
      line-height: 16px;
    }
    &--tiny {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
    &--center {
      text-align: center;
    }
  }

  .form {
    width: 340px;
  }

  .formInput {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .wrap {
    padding: 20px;
    max-width: 481px;
  }

  .home {
    &__container {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    &__text {
      text-align: center;
      max-width: 481px;
      color: #000000;
      font-weight: 400;
    }
    &__wrap {
      min-width: 95%;
      margin: 0 auto;
    }
    &__button {
      margin-left: 0;
      right: 20px;
      left: auto;
      bottom: 10px;
    }
  }

  .hint {
    width: 100%;
    left: 0;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (max-width: 480px) {
  .header {
    &--one {
      font-size: 20px;
    }
    &--two {
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 130px;
    }
    &--three {
      font-size: 14px;
    }
    &--four {
      font-size: 12px;
    }
  }

  .subtitle {
    font-size: 16px;
    max-width: 320px;
  }

  .text {
    &--normal {
      font-size: 16px;
    }
    &--small {
      font-size: 12px;
    }
    &--tiny {
      font-size: 12px;
    }
  }

  .form {
    width: 90%;
  }

  .formInput {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .wrap {
    padding: 30px 15px 35px 15px;
    max-width: 320px;
    // box-shadow: none;
  }

  .home {
    &__text {
      max-width: 310px;
    }
    &__wrap {
      min-width: 100%;
      margin: 0 auto;
    }
    &__button {
      margin-left: 0;
      right: 10px;
      left: auto;
      bottom: 10px;
    }
  }
  .home__button-text {
    display: none;
  }
  .hint {
    width: 100%;
    left: 0;
  }

  .link {
    &--normal {
      font-size: 14px;
    }
  }
}
