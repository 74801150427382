.pageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.maintenanceWrapper {
  width: 895px;
  height: 319px;
  background-color: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 60px;
  }
  .headerMaintenance {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 34px;
    width: 334px;
    margin-bottom: 17px;
    color: #39363E;
  }
  .descriptionMaintenance {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #39363E;
  }
}